import cx from 'classnames'

import { SanitySimpleGridModule } from '@data/sanity/queries/types/modules'

import { screens } from '@lib/theme'

import SimpleGridBlock from './simple-grid-block'

type SimpleGridProps = Pick<SanitySimpleGridModule, 'blocks'>

const getGridPhotoSizes = (columns: number) => {
  const sm = `(max-width: ${screens['sm']}) 100vw`
  const md = `(max-width: ${screens['md']}) ${100 / Math.min(columns, 2)}vw`
  const lg = `(max-width: ${screens['lg']}) ${100 / Math.min(columns, 4)}vw`
  const xl = `${100 / Math.min(columns, 4)}vw`

  return `${sm}, ${md}, ${lg}, ${xl}`
}

const SimpleGrid = ({ blocks }: SimpleGridProps) => {
  return (
    <div
      className={cx('grid grid-cols-1', {
        [`sm:grid-cols-${blocks.length}`]: blocks.length < 4,
        'sm:grid-cols-2 lg:grid-cols-4': blocks.length >= 4,
      })}
    >
      {blocks?.map((block) => (
        <div
          key={block._key}
          className={cx({ 'px-3 py-5': block._type === 'freeform' })}
        >
          <SimpleGridBlock
            block={block}
            photoSizes={getGridPhotoSizes(blocks.length)}
          />
        </div>
      ))}
    </div>
  )
}

export default SimpleGrid
