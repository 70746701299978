import cx from 'classnames'

import { type SanityFreeformBlock } from '@data/sanity/queries/types/blocks'

import ComplexPortableText from '@components/complex-portable-text'

type FreeformProps = Pick<
  SanityFreeformBlock,
  'content' | 'maxWidth' | 'textAlign'
>

const Freeform = ({ content, maxWidth, textAlign }: FreeformProps) => {
  if (!content) {
    return null
  }

  return (
    <ComplexPortableText
      content={content}
      className={cx('rc', maxWidth, textAlign)}
    />
  )
}

export default Freeform
