import { type SanityVideo } from '@data/sanity/queries/types/video'

import MuxVideo from '@components/video/mux-video'

interface VideoModuleProps extends SanityVideo {
  className?: string
}

const VideoModule = ({
  type,
  muxVideo,
  settings,
  className,
  aspectRatio,
}: VideoModuleProps) => {
  return (
    <div className={className}>
      {type === 'mux' && muxVideo && (
        <MuxVideo
          video={muxVideo}
          showControls={settings?.controls}
          autoplay={settings?.autoplay}
          loop={settings?.loop}
          muted={settings?.muted}
          customAspectRatio={aspectRatio}
        />
      )}
    </div>
  )
}

export default VideoModule
