import cx from 'classnames'

import { type SanityNestedSimpleGridBlock } from '@data/sanity/queries/types/blocks'

import NestedSimpleGridBlock from './nested-simple-grid-block'

type NestedSimpleGridProps = Pick<SanityNestedSimpleGridBlock, 'blocks'>

const NestedSimpleGrid = ({ blocks }: NestedSimpleGridProps) => {
  return (
    <div className="grid grid-cols-2 h-full">
      {blocks?.map((block) => (
        <div
          key={block._key}
          className={cx('', {
            'px-3 py-5': block._type === 'freeform',
          })}
        >
          <NestedSimpleGridBlock block={block} />
        </div>
      ))}
    </div>
  )
}

export default NestedSimpleGrid
