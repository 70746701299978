/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: string,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  )

  return dateTimeFormat.format(new Date(date))
}
